import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { useScrollFreeze } from '@hooks';
import { useSiteConfig } from '@querys/useSiteConfig';
import { AccordionToggle } from './accordionToggle';

import Close from '@svg/close.svg';
import Accordion from './accordion';

const searchVars = {
  open: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

const OuterDropdown = ({ i, expanded, setExpanded, questions, white }) => {
  const isOpen = i === expanded;
  return (
    <>
      <motion.div
        className='py-5 border-b flex flex-row justify-between items-center cursor-pointer pt-8 lg:pt-16'
        initial={false}
        onClick={() => setExpanded(isOpen ? false : i)}>
        <p className='text-xl leading-xl md:leading-4xl md:text-4xl font-semibold mr-12'>
          {questions.title}
        </p>
        <AccordionToggle
          className='focus:outline-none'
          navOpen={isOpen}
          white={white}
        />
      </motion.div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key='content'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}>
            <motion.div
              variants={{
                collapsed: { opacity: 0, scale: 1 },
                open: { opacity: 1, scale: 1 },
              }}
              transition={{ duration: 0.8 }}
              className='py-4'>
              <Accordion
                dropdown={questions.questions}
                noGrid={true}
                white={true}
              />
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

const Inner = ({ toggleFaq }) => {
  useScrollFreeze();
  const questions = useSiteConfig();
  const { faq } = questions || {};
  const [expanded, setExpanded] = useState(false);

  return (
    <motion.div
      variants={searchVars}
      initial='closed'
      animate='open'
      exit='closed'
      className='py-40 overflow-y-scroll z-50 bg-black w-screen h-full fixed top-0 items-center justify-center flex flex-col px-gutter lg:px-0 lg:grid grid-cols-14'>
      <div className='col-start-3 col-end-13 text-white'>
        <h1 className='title-block w-full text-center'>FAQs</h1>
        {faq.map((questions, i) => (
          <OuterDropdown
            white={true}
            key={i}
            i={i}
            expanded={expanded}
            setExpanded={setExpanded}
            questions={questions}
          />
        ))}
      </div>

      <button
        onClick={() => toggleFaq(false)}
        className='absolute top-0 lg:top-5 right-gutter lg:right-halfGutter focus:outline-none'>
        <Close className='w-10 lg:w-16' />
      </button>
    </motion.div>
  );
};

export const Faqs = ({ faqOpen, toggleFaq }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {faqOpen && <Inner key={faqOpen} toggleFaq={toggleFaq} />}
    </AnimatePresence>
  );
};
