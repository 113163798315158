import React, { useState } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import useEmblaCarousel from 'embla-carousel-react';
import Moment from 'react-moment';
import AnchorScroll from 'react-anchor-link-smooth-scroll';
import cx from 'classnames';
import FeaturedArtist from './featuredArtist';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line
import StandardVideo from '../media/standardVideo';

const SingleEventInfo = ({
  title,
  session,
  where,
  ticketsLink,
  featuredArtists,
  listingVideo,
  video,
  listingImage,
  isLandscape,
  series,
  freeEvent,
  hideTicketingInfo,
  isOneOff,
  venueLink,
  setPopupOpen,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    dragFree: true,
    align: 'start',
    containScroll: true,
  });

  const now = new Date();
  const isPast = session.every((date) => new Date(date) < now);

  return (
    <div className='overflow-hidden'>
      <div className='md:grid grid-cols-1 lg:grid-cols-2 gap-x-8 xl:gap-x-24 px-gutter mt-36 lg:mt-64 prose md:prose-lg text-black'>
        {/* Event Info */}
        {isLandscape && (
          <div className='mt-8  col-start-1 col-end-2 '>
            <div className=' w-full'>
              <p className='font-medium'>{series}</p>
              <h3 className='font-medium title-block m-0-important pb-3 lg:pb-16'>
                {title}
              </h3>
            </div>
          </div>
        )}

        {!isLandscape && (
          <div className='hidden md:block col-start-2 col-end-3 pt-28 pb-28 '>
            <div className='  relative aspect-w-2 aspect-h-3'>
              {listingVideo ? (
                <div>
                  <StandardVideo
                    url={video}
                    width='100%'
                    height='100%'
                    muted
                    playsinline
                    controls
                  />
                </div>
              ) : (
                <Image
                  className='w-full absolute object-cover top-0 left-0  h-full'
                  {...listingImage.image}
                />
              )}
            </div>
          </div>
        )}

        <div
          className={cx('mt-8 lg:mt-16  ', {
            'col-start-2 col-end-3': isLandscape,
            'col-start-1 col-end-2 row-start-1': !isLandscape,
          })}>
          {!isLandscape && (
            <div className='mt-8 lg:mt-16'>
              <div className=' w-full'>
                <p className='font-medium'>{series}</p>
                <h3 className='font-medium title-block m-0-important pb-3 lg:pb-16'>
                  {title}
                </h3>
              </div>
            </div>
          )}
          <div className='border-t border-grey w-full grid grid-cols-10'>
            <p className='col-span-3 lg:col-span-2'>When:</p>

            <div className='col-start-5 lg:col-start-4 col-span-6'>
              {session?.map((date, i) => {
                return (
                  <p key={i}>
                    <Moment withTitle format='ddd DD MMM YYYY - h:mm A'>
                      {date}
                    </Moment>
                  </p>
                );
              })}
            </div>
          </div>

          <div className='border-t border-grey w-full grid grid-cols-10'>
            <p className='col-span-3 lg:col-span-2'>Where:</p>
            <div className='col-start-5 lg:col-start-4 col-span-5'>
              <p>{where.title}</p>
              {venueLink && (
                <a
                  href={venueLink}
                  target='_blank'
                  rel='noreferrer'
                  className='mb-2 lg:mb-4 -mt-2 lg:-mt-4 block underline-important font-semibold'>
                  Venue Map
                </a>
              )}
            </div>
          </div>

          {ticketsLink && (
            <div className='border-t border-grey w-full grid grid-cols-10 items-center'>
              <p className='col-span-3 lg:col-span-2'>Concert info:</p>
              <AnchorScroll
                href='#concert-info'
                className='underline-important font-semibold col-start-5 lg:col-start-4 col-span-5'>
                Read more
              </AnchorScroll>
            </div>
          )}

          {ticketsLink && !hideTicketingInfo && (
            <div className='border-t border-grey w-full grid grid-cols-10 items-center'>
              <p className='col-span-3 lg:col-span-2'>Ticketing:</p>
              <p
                onClick={() => setPopupOpen(true)}
                className='underline-important font-semibold col-start-5 lg:col-start-4 col-span-7 cursor-pointer'>
                Single Concert vs Series Packages
              </p>
            </div>
          )}
          {freeEvent && !hideTicketingInfo && (
            <div className='border-t border-grey w-full grid grid-cols-10 items-center'>
              <p className='col-span-3 lg:col-span-2'>Ticketing:</p>
              <p className='underline-important font-semibold col-start-5 lg:col-start-4 col-span-7 '>
                Free Admission
              </p>
            </div>
          )}

          <div className='mt-8 lg:mt-14 flex justify-around sm:justify-start xl:block'>
            <div className='inline'>
              {ticketsLink && !isPast ? (
                <a
                  href={ticketsLink}
                  target='_blank'
                  rel='noreferrer'
                  className='btn-color-white btn-white'>
                  Book Tickets
                </a>
              ) : (
                <AnchorScroll
                  href='#concert-info'
                  className='btn-color-white btn-white'>
                  Concert Info
                </AnchorScroll>
              )}
            </div>
          </div>
        </div>
      </div>
      {isLandscape && (
        <div className='px-gutter mt-22 lg:pt-40 pb-10 '>
          <div className='  relative aspect-w-4 aspect-h-2'>
            {listingVideo ? (
              <div>
                <StandardVideo
                  url={video}
                  width='100%'
                  height='100%'
                  muted
                  playsinline
                  controls
                />
              </div>
            ) : (
              <Image
                className='w-full absolute object-cover top-0 left-0  h-full'
                {...listingImage.image}
              />
            )}
          </div>
        </div>
      )}

      <div
        className='relative hidden lg:grid lg:grid-cols-2 lg:gap-x-20 gap-y-20 lg:px-gutter  lg:mt-28'
        style={{ direction: 'rtl' }}>
        <h1 className='absolute font-medium text-5xl top-0 left-gutter'>
          Featured Artists
        </h1>
        {featuredArtists?.map((item, i) => (
          <FeaturedArtist
            key={i}
            {...item}
            className={`${i === 1 && 'mt-96'} ${
              i % 2 === 0 && i !== 0 && '-mt-96'
            }`}
          />
        ))}
      </div>

      {/* Mobile Image Carousel */}
      <div ref={emblaRef} className='cursor-none lg:hidden px-gutter'>
        <div className='flex w-10/12 lg:w-1/3 gap-x-6 mt-16'>
          {featuredArtists?.map((item) => (
            <FeaturedArtist {...item} className='flex-shrink-0 w-full' />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleEventInfo;
