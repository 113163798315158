import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import useEmblaCarousel from 'embla-carousel-react';
import { StaticImage } from 'gatsby-plugin-image';
import Moment from 'react-moment';
import { AnimatePresence } from 'framer-motion';
import AnchorScroll from 'react-anchor-link-smooth-scroll';
import cx from 'classnames';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '@components/pageMeta';
import Layout from '@components/layout';
import { PortableTextBlock } from '@modules/portableTextBlock';
import { GetEvents } from '@querys/getEvents';
import { Faqs } from '@blocks/faqs';
import { TicketingPopup } from '../components/ui/ticketingPopup';
import SanityImage from 'gatsby-plugin-sanity-image';
import SeriesInfo from '@components/event/seriesinfo';
import SingleEventInfo from '@components/event/singleEventInfo';

const OtherEvents = ({ currentSlug }) => {
  const events = GetEvents();
  const sortedEvents = events
    .filter(
      (event) =>
        new Date(event?.session[event.session.length - 1]) > new Date(),
    )
    .sort((a, b) => new Date(a.session[0]) - new Date(b.session[0]));
  let count = 0;
  return (
    <div className='grid grid-cols-14 mt-24 lg:mt-64 pb-20 lg:pb-48'>
      <div className='col-start-2 col-end-14 flex flex-col lg:flex-row justify-between items-center'>
        <h2 className='text-3xl leading-3xl md:text-5xl md:leading-5xl font-medium'>
          Other Events
        </h2>
        <Link
          to='/events'
          className='text-sm leading-sm md:text-lg md:leading-lg underline font-semibold'>
          View all
        </Link>
      </div>

      <div className='col-start-2 col-end-14 grid grid-cols-1 md:grid-cols-2 gap-x-20 gap-y-12 mt-14 lg:mt-20'>
        {sortedEvents.map((otherEvent, i) => {
          const {
            title,
            session,
            slug,
            ticketsLink,
            where,
            listingImage,
            series,
            isOneOff,
            buttonText,
          } = otherEvent || {};

          if (slug?.current !== currentSlug?.current && count < 2) {
            count++;
            return (
              <div key={i} className='@container prose md:prose-lg text-black'>
                <div className='border-b border-grey w-full'>
                  <p>{series}</p>
                  <h2 className='font-medium'>{title}</h2>
                </div>

                <div className='border-b border-grey w-full grid grid-cols-10'>
                  <p className='col-span-3 lg:col-span-2'>When:</p>

                  <div className='col-start-5 lg:col-start-4 col-span-6'>
                    {session.map((date, i) => {
                      return (
                        <p key={i}>
                          <Moment withTitle format='ddd DD MMM YYYY - h:mm A'>
                            {date}
                          </Moment>
                        </p>
                      );
                    })}
                  </div>
                </div>

                <div className='border-b border-grey w-full grid grid-cols-10'>
                  <p className='col-span-3 lg:col-span-2'>Where:</p>
                  <div className='col-start-5 lg:col-start-4 col-span-7'>
                    <p>{where.title}</p>
                  </div>
                </div>

                <div className='mt-8 mb-8 lg:mb-0 lg:mt-14 @[460px]:flex-row gap-x-0 @[460px]:gap-x-3 flex-col flex'>
                  <div className='inline-flex'>
                    <Link
                      to={`/events/${slug.current}`}
                      className='btn-color-white btn-white'>
                      Concert Info
                    </Link>
                  </div>
                  {!isOneOff && buttonText !== 'Hidden' && (
                    <div className='inline-flex'>
                      <Link
                        to={
                          ticketsLink
                            ? ticketsLink
                            : '/concert-series-subscription'
                        }
                        target={ticketsLink ? '_blank' : '_self'}
                        rel={ticketsLink ? 'noreferrer' : ''}
                        className='btn-color-black btn-black text-wrap'>
                        {buttonText}
                      </Link>
                    </div>
                  )}
                </div>

                <div className='lg:mt-16 overflow-hidden'>
                  <Link to={`/events/${slug?.current}`}>
                    {listingImage?.image && (
                      <Image
                        className='transform hover:scale-105 duration-300 ease-in-out m-0-important'
                        {...listingImage?.image}
                      />
                    )}
                  </Link>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

const FAQ = ({ faqOpen, toggleFaq }) => {
  return (
    <>
      <div className='grid w-full bg-black py-16 lg:py-28 text-center grid-cols-14 mt-24 lg:mt-0'>
        <div className='col-start-3 col-end-13 lg:col-start-4 lg:col-end-12 text-white text-2xl leading-2xl md:text-5xl md:leading-5xl'>
          <h2 className='font-semibold'>Attending a concert?</h2>
          <h3>Plan your visit.</h3>
        </div>
        <div className='col-start-4 col-end-12 mt-8 lg:mt-12'>
          <button
            onClick={() => toggleFaq(true)}
            className='btn-faq btn-black text-sm md:text-lg'>
            Read our FAQs
          </button>
        </div>
      </div>

      <AnimatePresence exitBeforeEnter>
        <Faqs faqOpen={faqOpen} toggleFaq={toggleFaq} />
      </AnimatePresence>
    </>
  );
};

const Description = ({ event }) => {
  const {
    repertoire,
    description,
    ticketsLink,
    sponsors,
    sponsorsTitle,
    additionalInfo,
    isOneOff,
    buttonText,
  } = event || {};

  return (
    <div
      id='concert-info'
      className={`max-w-none grid grid-cols-14 text-black pt-14 lg:pt-28 lg:pb-64`}>
      <div className={`col-start-2 col-end-14 lg:col-start-2 lg:col-end-6`}>
        {repertoire.map((row, i) => (
          <div
            key={i}
            className={`text-base leading-base md:text-xl md:leading-xl ${
              i !== 0 ? 'mt-4' : 'mt-1'
            }`}>
            <p className='font-semibold'>{row.heading}</p>
            <p>{row.text}</p>
          </div>
        ))}
      </div>
      <div
        className={`text-black prose md:prose-lg lg:prose-mt-0 col-start-2 col-end-14 lg:col-end-14 lg:col-start-6 lg:ml-12 mt-14 lg:mt-0`}>
        {description && <PortableTextBlock text={description} />}
        {additionalInfo.map((info) => (
          <div className='text-base leading-base md:text-xl md:leading-xl mt-16 mb-10'>
            <p className='font-semibold '>{info.heading}</p>
            <p className='mt-4 lg:mt-10'>{info.text}</p>
          </div>
        ))}

        {sponsors?.length > 0 && (
          <span className='font-semibold mt-12 block'>{sponsorsTitle}</span>
        )}
        <div className='flex flex-col md:flex-row gap-x-4 gap-y-4 mt-4'>
          {sponsors?.map(({ image, url }, i) => (
            <a href={url} target='_blank' rel='noreferrer'>
              {image && (
                <Image
                  key={i}
                  {...image}
                  className='w-32 h-auto md:h-12 md:w-auto !my-0'
                />
              )}
            </a>
          ))}
        </div>

        {!isOneOff && buttonText !== 'Hidden' && (
          <div className='mt-20'>
            <Link
              to={ticketsLink ? ticketsLink : '/concert-series-subscription'}
              target={ticketsLink ? '_blank' : '_self'}
              rel={ticketsLink ? 'noreferrer' : ''}
              className='btn-color-black btn-black'>
              {buttonText}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

const EventTemplate = ({ data: { event } }) => {
  const { meta, settings, slug, isOneOff } = event || {};

  const [faqOpen, setFaqOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <>
      <style>
        {`\
                .btn-color-black{\
                    color: ${settings?.bgColor?.hex} !important;\
                }\
                .btn-color-black:hover{\
                    color: #000 !important;\
                }\

                .btn-color-white:hover{\
                    color: ${settings?.bgColor?.hex} !important;\
                }\

                .btn-faq{\
                  border: 1px solid #fff !important;\
                }\
                .btn-faq:hover{\
                    background-color: #fff !important;\
                }\

                @media(min-width: 768px){\
                    .btn-faq{\
                        padding: 1rem 6rem !important;\
                      }\
                }
            `}
      </style>

      <Layout settings={settings}>
        <PageMeta {...meta} />
        {isOneOff ? (
          <SingleEventInfo setPopupOpen={setPopupOpen} {...event} />
        ) : (
          <SeriesInfo setPopupOpen={setPopupOpen} {...event} />
        )}
        {/* <Info setPopupOpen={setPopupOpen} {...event} /> */}
        <Description event={event} />
        <FAQ faqOpen={faqOpen} toggleFaq={setFaqOpen} />
        <OtherEvents currentSlug={slug} />
        <TicketingPopup popupOpen={popupOpen} setPopupOpen={setPopupOpen} />
      </Layout>
    </>
  );
};

export default EventTemplate;

export const eventQuery = graphql`
  query standardEventQuery($slug: String!) {
    event: sanityEvent(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
      }
      settings {
        revereseText
        reverseHeader
        searchExcerpt
        bgColor {
          hex
        }
      }
      slug {
        current
      }
      additionalInfo {
        heading
        text
      }
      description: _rawEventDescription(resolveReferences: { maxDepth: 100 })
      sponsors {
        image {
          ...ImageWithPreview
        }
        url
      }
      sponsorsTitle
      featuredArtists {
        name
        position {
          name
        }
        bioImage {
          image {
            ...ImageWithPreview
          }
        }
      }
      series
      session
      ticketsLink
      listingVideo
      video
      listingImage {
        image {
          ...ImageWithPreview
        }
      }
      isLandscape
      title
      freeEvent
      hideTicketingInfo
      isOneOff
      buttonText
      where {
        id
        location
        title
      }
      venueLink
      repertoire {
        heading
        text
      }
    }
  }
`;
