import React, { useState } from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { StaticImage } from 'gatsby-plugin-image';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

const FeaturedArtist = ({ name, bioImage, position, ...props }) => {
  return (
    <div {...props}>
      <div className='mb-12 relative aspect-w-4 aspect-h-5 artist-gradient'>
        {bioImage?.image ? (
          <Image
            className='w-full absolute object-cover top-0 left-0  h-full'
            {...bioImage?.image}
          />
        ) : (
          <StaticImage src={'@images/missing.jpg'} />
        )}
      </div>
      <p className='capitalize text-base md:text-xl'>
        {name} - {position?.name}
      </p>
    </div>
  );
};

export default FeaturedArtist;
